<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Demande de Réservation 1",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Location",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Demande de Réservation 1",
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalRows: 0,
      totalRowsDisable: 0,
      currentPage: 1,
  
      filter: null,
      filterOn: [],
      demandesData: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "client", label: "Client" },
        { key: "clienttel", sortable: true, label: "Téléphone" },
        { key: "proprietaire", label: "Propriétaire" },
        { key: "proprietairertel", sortable: true, label: "Téléphone" },

        { key: "dateDepart", label: "Départ" },
        { key: "dateRetour", sortable: true, label: "Retour" },
        { key: "dateVirement", sortable: true, label: "Paiements" },
        { key: "messageLaisse", sortable: true, label: "Message" },
        { key: "nombreJour", sortable: true, label: "Nb Jour" },
        { key: "modePaiement", sortable: true, label: "Mode paiement" },
        { key: "fraisReservation", sortable: true, label: "Frais réservation" },
      ],

      startValue: 1,
      endValue: 100,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetDemandeReserv: [],
      parBon: 100,
      recherche: "",
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const demandes = await apiRequest(
        "GET",
        "location/reservation/clients",
        undefined,
        false
      );

      if (demandes && demandes.data) {

        this.totalValue=demandes.data.total
        this.totalRows=demandes.data.total
     
        const filtered =  demandes.data.reservations.sort((a,b)=>b.id - a.id);

        console.log("filterd data for paiement",filtered)
        const demandesTable = filtered.map((recherche) => {
          if (recherche.client != undefined) {
            return {
              id: recherche.id,

              proprietaire: recherche.information_contact_nom,
              pWa: recherche.information_contact_phone.replace("+","")||"",

              proprietairertel: recherche.information_contact_phone,
              client:
                recherche.client.personne != null
                  ? recherche.client.personne.firstname +
                    " " +
                    recherche.client.personne.lastname
                  : "",
              clienttel:
                recherche.client.personne.tel &&
                recherche.client.personne.tel != ""
                  ? recherche.client.personne.indicatifTel +
                    recherche.client.personne.tel
                  : "",

              clientWa: (recherche.client.personne.indicatifTel +recherche.client.personne.tel).replace("+","")||"",
              dateDepart: new Date(recherche.dateDepart).toLocaleString(
                "fr-FR"
              ),
              dateRetour: new Date(recherche.dateRetour).toLocaleDateString(
                "fr-FR"
              ),
              dateVirement: new Date(recherche.dateVirement).toLocaleDateString(
                "fr-FR"
              ),
              messageLaisse: recherche.messageLaisser,
              nombreJour: recherche.nombreJours,
              modePaiement: recherche.modePaiement,
              fraisReservation: recherche.fraisReservation,
            };
          }
        });
        this.demandesData = demandesTable;
        /*  .forEach(element => {
      if(element.conducteur!=null)this.demandesData.push(element)
    }); */
        console.log("les variable prises ", this.demandesData);

        this.totalRows = this.demandesData.length;
        this.totalValue = this.demandesData.length;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
      //this.create();
    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars

    async actif(page, limit) {
      //Chargement des données

      const data = await apiRequest(
        "GET",
        "admin/trajets/demandes?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
     
        const demandesTableReserve = data.data.reservations.map((recherche) => {
          if (recherche.client != null && recherche.conducteur != null) {
            /*  return {
            id: recherche.id,
            itinéraire: recherche.idTrajet.lieuDepart + " " + ">>" + " " + recherche.idTrajet.lieuArrivee,
            conducteur: recherche.conducteur.personne.firstname + " " + recherche.conducteur.personne.lastname,
            conducteurtel:
              recherche.conducteur.personne.tel && recherche.conducteur.personne.tel != ""
                ? recherche.conducteur.personne.indicatifTel + recherche.conducteur.personne.tel
                : "",
            userp: recherche.client.personne.firstname + " " + recherche.client.personne.lastname,
            telp:
              recherche.client.personne.tel && recherche.client.personne.tel != ""
                ? recherche.client.personne.indicatifTel + recherche.client.personne.tel
                : "",
            date: new Date(recherche.dateReservation).toLocaleString("fr-FR"),
            depart: new Date(recherche.idTrajet.dateHeureDepart).toLocaleDateString("fr-FR"),
            state: recherche.statut,
          }; */
          
          return {
              id: recherche.id,

              proprietaire: recherche.information_contact_nom,

              proprietairertel: recherche.information_contact_phone,
              pWa: recherche.information_contact_phone.replace("+","")||"",

              client:
                recherche.client.personne != null
                  ? recherche.client.personne.firstname +
                    " " +
                    recherche.client.personne.lastname
                  : "",
                  clientWa: (recherche.client.personne.indicatifTel +recherche.client.personne.tel).replace("+","")||"",

              clienttel:
                recherche.client.personne.tel &&
                recherche.client.personne.tel != ""
                  ? recherche.client.personne.indicatifTel +
                    recherche.client.personne.tel
                  : "",
              dateDepart: new Date(recherche.dateDepart).toLocaleString(
                "fr-FR"
              ),
              dateRetour: new Date(recherche.dateRetour).toLocaleDateString(
                "fr-FR"
              ),
              dateVirement: new Date(recherche.dateVirement).toLocaleDateString(
                "fr-FR"
              ),
              messageLaisse: recherche.messageLaisser,
              nombreJour: recherche.nombreJours,
              modePaiement: recherche.modePaiement,
              fraisReservation: recherche.fraisReservation,
            };
        
        
        }
        });

        this.userChargeLoader = false;
        this.newDatTrajetDemandeReserv = [];
        demandesTableReserve.forEach((element) => {
          if (element.conducteur != null)
            this.newDatTrajetDemandeReserv.push(element);
        });

        this.userChargeLoader = false;
      }
    },

    desactivity(row) {
      this.showdisable = true;
      this.rowCall = row.id;
    },
    async desactive() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/alerte-active",
        {
          id: this.rowCall,
          active: false,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.init();
      }
    },
    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },

    async userSearch() {
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "location/reservation/recherche",
        { mots: this.recherche},
        undefined
      );
      if (dataSearch && dataSearch.data) {
        const demandesTable = dataSearch.data.map((recherche) => {
          if (recherche.client != null ) {
            


            return {
              id: recherche.id,

              proprietaire: recherche.information_contact_nom,

              proprietairertel: recherche.information_contact_phone,
              client:
                recherche.client.personne != null
                  ? recherche.client.personne.firstname +
                    " " +
                    recherche.client.personne.lastname
                  : "",
              clienttel:
                recherche.client.personne.tel &&
                recherche.client.personne.tel != ""
                  ? recherche.client.personne.indicatifTel +
                    recherche.client.personne.tel
                  : "",
              dateDepart: new Date(recherche.dateDepart).toLocaleString(
                "fr-FR"
              ),
              dateRetour: new Date(recherche.dateRetour).toLocaleDateString(
                "fr-FR"
              ),
              dateVirement: new Date(recherche.dateVirement).toLocaleDateString(
                "fr-FR"
              ),
              messageLaisse: recherche.messageLaisser,
              nombreJour: recherche.nombreJours,
              modePaiement: recherche.modePaiement,
              fraisReservation: recherche.fraisReservation,
            };
          }
        });

        this.newDatTrajetDemandeReserv = demandesTable;

        this.userChargeLoader = false;
        this.totalRows = demandesTable.length;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="demandesData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div
            class="d-flex align-items-center otherThing"
            v-if="userChargeLoader === true"
          >
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <h4 class="card-title mt-4">
                  Total de demandes : {{ this.totalRows }}
                </h4>
              </div>
              <transition mode="out-in">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <li>
                      <span class="interval"> {{ startValue }}</span> -
                      <span class="interval">{{ endValue }}</span> sur
                      {{ totalValue }}
                    </li>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <li>
                      <button
                        class="myBouton"
                        @click="firstVal()"
                        :disabled="startValue == 1"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Prem"
                      >
                        <ion-icon name="play-skip-back-outline"></ion-icon>
                      </button>
                      <button
                        type="button"
                        class="myBouton"
                        @click="decrement()"
                        :disabled="endValue == this.parBon"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Préc"
                      >
                        <ion-icon name="chevron-back-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="increment()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Suiv"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="chevron-forward-outline"></ion-icon>
                      </button>
                      <button
                        class="myBouton"
                        @click="incrementToLast()"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Dern"
                        :disabled="endValue >= totalValue"
                      >
                        <ion-icon name="play-skip-forward-outline"></ion-icon>
                      </button>
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des demandes de réservation</a
                    >
                  </template>
                  <div class="row d-flex justify-content-between mt-4">
                    <div class="p-3"></div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="recherche"
                          @keyup.enter="userSearch"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="
                        newDatTrajetDemandeReserv.length != 0
                          ? newDatTrajetDemandeReserv
                          : demandesData
                      "
                      :fields="fields"
                      responsive="sm"
                  
                  
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template
                        v-slot:cell(clienttel)="row"
                        style="text-align: center"
                      >
                        <a
                          :href="'https://wa.me/' + row.item.clientWa"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template>
                      <template
                        v-slot:cell(proprietairertel)="row"
                        
                      >
                        <a
                          :href="'https://wa.me/' + row.item.pWa"
                          class="text-success"
                          v-b-tooltip.hover
                          title="Envoyer un message whatsapp"
                          target="_blank"
                        >
                          {{ row.value }}
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <!--   <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          pagination 
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col">
                      <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                      <transition mode="out-in">
                        <div
                          class="dataTables_paginate paging_simple_numbers float-right"
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <li>
                              <span class="interval"> {{ startValue }}</span> 
                              <span class="interval">{{ endValue }}</span> sur
                              {{ totalValue }}
                            </li>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <li>
                              <button
                                class="myBouton"
                                @click="firstVal()"
                                :disabled="startValue == 1"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Prem"
                              >
                                <ion-icon
                                  name="play-skip-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                type="button"
                                class="myBouton"
                                @click="decrement()"
                                :disabled="endValue == parBon"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Préc"
                              >
                                <ion-icon
                                  name="chevron-back-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="increment()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Suiv"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="chevron-forward-outline"
                                ></ion-icon>
                              </button>
                              <button
                                class="myBouton"
                                @click="incrementToLast()"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Dern"
                                :disabled="endValue >= totalValue"
                              >
                                <ion-icon
                                  name="play-skip-forward-outline"
                                ></ion-icon>
                              </button>
                            </li>
                          </ul>
                        </div>
                      </transition>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivity"
          >Oui</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  height: 35px;
  top: -50%;
  margin-top: 10px;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #d6d2f8;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
